const user_scopes = {
    descriptions: {
        custom_data: 'Ваши пользовательские данные',
        email: 'Ваш адрес электронной почты',
        phone: 'Ваш номер телефона',
        profile: 'Ваше имя, имя пользователя, аватар и другая информация профиля',
        roles: 'Ваши роли',
        identities: 'Ваши связанные социальные идентификаторы',
        'urn:logto:scope:organizations': 'Информация о ваших организациях',
        'urn:logto:scope:organization_roles': 'Ваши организационные роли',
        address: 'Ваш адрес',
    },
};
export default Object.freeze(user_scopes);
