const user_details = {
    page_title: 'Детали пользователя',
    back_to_users: 'Вернуться к управлению пользователями',
    created_title: 'Пользователь успешно создан',
    created_guide: 'Вот информация, которая поможет пользователю в процессе входа в систему.',
    created_email: 'Адрес электронной почты:',
    created_phone: 'Номер телефона:',
    created_username: 'Имя пользователя:',
    created_password: 'Пароль:',
    menu_delete: 'Удалить',
    delete_description: 'Это действие нельзя отменить. Оно окончательно удалит пользователя.',
    deleted: 'Пользователь успешно удален',
    reset_password: {
        reset_title: 'Вы уверены, что хотите сбросить пароль?',
        generate_title: 'Вы уверены, что хотите сгенерировать пароль?',
        content: 'Это действие нельзя отменить. Это сбросит информацию для входа пользователя.',
        reset_complete: 'Пользователь был сброшен',
        generate_complete: 'Пароль был сгенерирован',
        new_password: 'Новый пароль:',
        password: 'Пароль:',
    },
    tab_settings: 'Настройки',
    tab_roles: 'Роли пользователя',
    tab_logs: 'Журналы пользователя',
    tab_organizations: 'Организации',
    authentication: 'Аутентификация',
    authentication_description: 'У каждого пользователя есть профиль, содержащий всю информацию о пользователе. Он состоит из основных данных, социальных идентификаторов и пользовательских данных.',
    user_profile: 'Профиль пользователя',
    field_email: 'Адрес электронной почты',
    field_phone: 'Номер телефона',
    field_username: 'Имя пользователя',
    field_password: 'Пароль',
    field_name: 'Имя',
    field_avatar: 'URL изображения аватара',
    field_avatar_placeholder: 'https://your.cdn.domain/avatar.png',
    field_custom_data: 'Пользовательские данные',
    field_custom_data_tip: 'Дополнительная информация о пользователе, не указанная в заранее определенных свойствах пользователя, таких как предпочтительный цвет и язык пользователя.',
    field_profile: 'Профиль',
    field_profile_tip: 'Дополнительные стандартные утверждения OpenID Connect, которые не включены в свойства пользователя. Обратите внимание, что все неизвестные свойства будут удалены. Дополнительную информацию см. в <a>ссылке на свойство профиля</a>.',
    field_connectors: 'Социальные подключения',
    field_sso_connectors: 'Корпоративные подключения',
    custom_data_invalid: 'Пользовательские данные должны быть допустимым JSON-объектом',
    profile_invalid: 'Профиль должен быть допустимым JSON-объектом',
    password_already_set: 'Пароль уже установлен',
    no_password_set: 'Пароль не установлен',
    connectors: {
        connectors: 'Подключения',
        user_id: 'ID пользователя',
        remove: 'Удалить',
        connected: 'Этот пользователь подключен к нескольким социальным подключениям.',
        not_connected: 'Пользователь не подключен к социальным подключениям',
        deletion_confirmation: 'Вы удаляете существующую личность <name/>. Вы уверены, что хотите продолжить?',
    },
    sso_connectors: {
        connectors: 'Подключения',
        enterprise_id: 'ID корпорации',
        connected: 'Этот пользователь подключен к нескольким поставщикам корпоративной идентификации для однократного входа.',
        not_connected: 'Пользователь не подключен к поставщикам корпоративного идентификатора для однократного входа.',
    },
    mfa: {
        field_name: 'Двухфакторная аутентификация',
        field_description: 'Этот пользователь включил двухэтапные факторы аутентификации.',
        name_column: 'Двухфакторная аутентификация',
        field_description_empty: 'Этот пользователь не включил двухфакторную аутентификацию.',
        deletion_confirmation: 'Вы удаляете существующий <name/> для двухэтапной верификации. Вы уверены, что хотите продолжить?',
    },
    suspended: 'Приостановлен',
    suspend_user: 'Приостановить пользователя',
    suspend_user_reminder: 'Вы уверены, что хотите приостановить этого пользователя? Пользователь не сможет войти в ваше приложение, и он не сможет получить новый токен доступа после истечения срока действия текущего токена. Кроме того, любые API-запросы, сделанные этим пользователем, завершатся неудачей.',
    suspend_action: 'Приостановить',
    user_suspended: 'Пользователь был приостановлен.',
    reactivate_user: 'Возобновить пользователя',
    reactivate_user_reminder: 'Вы уверены, что хотите возобновить этого пользователя? Это позволит любые попытки входа в систему для этого пользователя.',
    reactivate_action: 'Возобновить',
    user_reactivated: 'Пользователь был возобновлен.',
    roles: {
        name_column: 'Роль пользователя',
        description_column: 'Описание',
        assign_button: 'Назначить роли',
        delete_description: 'Это действие удалит эту роль у данного пользователя. Роль все еще будет существовать, но она больше не будет связана с этим пользователем.',
        deleted: '{{name}} был(а) успешно удален(а) из этого пользователя.',
        assign_title: 'Назначить роли {{name}}',
        assign_subtitle: 'Найдите подходящие роли пользователя, используя поиск по имени, описанию или идентификатору роли.',
        assign_role_field: 'Назначить роли',
        role_search_placeholder: 'Поиск по названию роли',
        added_text: '{{value, number}} добавлен(а)',
        assigned_user_count: '{{value, number}} пользователей',
        confirm_assign: 'Назначить роли',
        role_assigned: 'Роль(и) успешно назначена(ы)',
        search: 'Поиск по названию роли, описанию или ID',
        empty: 'Нет доступных ролей',
    },
    warning_no_sign_in_identifier: 'Пользователь должен иметь хотя бы один из идентификаторов входа (имя пользователя, электронная почта, номер телефона или социальная сеть), чтобы войти. Вы уверены, что хотите продолжить?',
    personal_access_tokens: {
        title: 'Персональный токен доступа',
        title_other: 'Персональные токены доступа',
        title_short: 'токен',
        empty: 'У пользователя нет персональных токенов доступа.',
        create: 'Создать новый токен',
        tip: 'Персональные токены доступа (PATs) обеспечивают безопасный способ предоставления пользователями токенов доступа без использования своих учетных данных и интерактивного входа. Это полезно для CI/CD, скриптов или приложений, которым необходимо программно получать доступ к ресурсам.',
        value: 'Значение',
        created_at: 'Создан',
        expires_at: 'Срок действия истекает',
        never: 'Никогда',
        create_new_token: 'Создать новый токен',
        delete_confirmation: 'Это действие нельзя отменить. Вы уверены, что хотите удалить этот токен?',
        expired: 'Истек',
        expired_tooltip: 'Этот токен истек {{date}}.',
        create_modal: {
            title: 'Создать персональный токен доступа',
            expiration: 'Срок действия',
            expiration_description: 'Токен истечет {{date}}.',
            expiration_description_never: 'Токен никогда не истекает. Мы рекомендуем установить дату истечения для повышения безопасности.',
            days: '{{count}} день',
            days_other: '{{count}} дней',
            created: 'Токен {{name}} был успешно создан.',
        },
        edit_modal: {
            title: 'Изменить персональный токен доступа',
            edited: 'Токен {{name}} был успешно отредактирован.',
        },
    },
};
export default Object.freeze(user_details);
