const users = {
    page_title: 'Управление пользователями',
    title: 'Управление пользователями',
    subtitle: 'Управление идентификацией пользователей, включая создание пользователей, редактирование информации о пользователях, просмотр журналов пользователей, сброс пароля и удаление пользователей.',
    create: 'Добавить пользователя',
    create_subtitle: 'Введите хотя бы одно из следующих полей для продолжения.',
    error_missing_identifier: 'Чтобы создать пользователя, вам нужно указать хотя бы один идентификатор.',
    user_name: 'Пользователь',
    application_name: 'От приложения',
    latest_sign_in: 'Последний вход',
    create_form_username: 'Имя пользователя',
    create_form_password: 'Пароль',
    create_form_name: 'Полное имя',
    placeholder_name: 'Иван Иванов',
    placeholder_email: 'ivan@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+7 (123) 456-78-90',
    unnamed: 'Без имени',
    search: 'Поиск по имени, электронной почте, телефону или имени пользователя',
    check_user_detail: 'Просмотреть информацию о пользователе',
    placeholder_title: 'Управление пользователями',
    placeholder_description: 'Каждый пользователь имеет профиль, содержащий всю информацию о пользователе. Он состоит из базовых данных, социальных идентификаторов и настраиваемых данных.',
};
export default Object.freeze(users);
