const usage = {
    status_active: 'В использовании',
    status_inactive: 'Не используется',
    limited_status_quota_description: '(Первые {{quota}} включены)',
    unlimited_status_quota_description: '(Включено)',
    disabled_status_quota_description: '(Не включено)',
    usage_description_with_unlimited_quota: '{{usage}}<span> (Неограниченно)</span>',
    usage_description_with_limited_quota: '{{usage}}<span> (Первые {{basicQuota}} включены)</span>',
    usage_description_without_quota: '{{usage}}<span> (Не включено)</span>',
    mau: {
        title: 'MAU',
        tooltip: 'MAU — это уникальный пользователь, который обменял хотя бы один токен с Logto в течение расчетного цикла. Безлимитно для Pro плана. <a>Узнать больше</a>',
        tooltip_for_enterprise: 'MAU — это уникальный пользователь, который обменял хотя бы один токен с Logto в течение расчетного цикла. Неограниченно для План компании.',
    },
    organizations: {
        title: 'Организации',
        tooltip: 'Дополнительная функция с фиксированной ставкой ${{price, number}} в месяц. Цена не зависит от количества организаций или уровня их активности.',
        description_for_enterprise: '(Включено)',
        tooltip_for_enterprise: 'Включение зависит от вашего плана. Если функция организации не включена в ваш начальный контракт, она будет добавлена ​​к вашему счету, когда вы её активируете. Дополнение стоит ${{price, number}}/месяц, независимо от количества организаций и их активности.',
        tooltip_for_enterprise_with_numbered_basic_quota: 'Ваш план включает первые {{basicQuota}} организации бесплатно. Если вам нужно больше, вы можете добавить их с функцией организации за фиксированную цену ${{price, number}} в месяц, независимо от количества организаций и их уровня активности.',
    },
    mfa: {
        title: 'MFA',
        tooltip: 'Дополнительная функция с фиксированной ставкой ${{price, number}} в месяц. Цена не зависит от количества используемых факторов аутентификации.',
        tooltip_for_enterprise: 'Включение зависит от вашего плана. Если функция MFA не включена в ваш начальный контракт, она будет добавлена ​​к вашему счету, когда вы её активируете. Дополнение стоит ${{price, number}}/месяц, независимо от количества используемых факторов аутентификации.',
    },
    enterprise_sso: {
        title: 'Корпоративное SSO',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за SSO соединение в месяц.',
        tooltip_for_enterprise: 'Дополнительная функция с ценой ${{price, number}} за SSO соединение в месяц. Первые {{basicQuota}} SSO включены и бесплатны в вашем плановом контракте.',
    },
    api_resources: {
        title: 'API ресурсы',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за ресурс в месяц. Первые 3 API ресурса предоставляются бесплатно.',
        tooltip_for_enterprise: 'Первые {{basicQuota}} API ресурса включены и бесплатны в вашем плановом контракте. Если вам нужно больше, то ${{price, number}} за API ресурс в месяц.',
    },
    machine_to_machine: {
        title: 'Machine-to-machine',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за приложение в месяц. Первое machine-to-machine приложение предоставляется бесплатно.',
        tooltip_for_enterprise: 'Первое {{basicQuota}} machine-to-machine приложение бесплатно в вашем плановом контракте. Если вам нужно больше, то ${{price, number}} за приложение в месяц.',
    },
    tenant_members: {
        title: 'Члены арендатора',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за участника в месяц. Первые 3 члена арендатора предоставляются бесплатно.',
        tooltip_for_enterprise: 'Первые {{basicQuota}} члена арендатора включены и бесплатны в вашем плановом контракте. Если вам нужно больше, то ${{price, number}} за члена арендатора в месяц.',
    },
    tokens: {
        title: 'Токены',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за {{tokenLimit}} токенов. Первые 1 {{basicQuota}} токенов включены.',
        tooltip_for_enterprise: 'Первые {{basicQuota}} токенов включены и бесплатны в вашем плановом контракте. Если вам нужно больше, то ${{price, number}} за {{tokenLimit}} токенов в месяц.',
    },
    hooks: {
        title: 'Хуки',
        tooltip: 'Дополнительная функция с ценой ${{price, number}} за хук. Первые 10 хуков включены.',
        tooltip_for_enterprise: 'Первые {{basicQuota}} хуков включены и бесплатны в вашем плановом контракте. Если вам нужно больше, то ${{price, number}} за хук в месяц.',
    },
    pricing: {
        add_on_changes_in_current_cycle_notice: 'Если вы внесете изменения в течение текущего расчетного цикла, ваш следующий счет может слегка увеличиться за первый месяц после изменения. Это будет ${{price, number}} базовой цены плюс дополнительные расходы на неучтенные объемы использования из текущего цикла и полную плату за следующий цикл. <a>Узнать больше</a>',
    },
};
export default Object.freeze(usage);
