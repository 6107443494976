const tabs = {
    get_started: 'Начало',
    dashboard: 'Панель управления',
    applications: 'Приложения',
    api_resources: 'Ресурсы API',
    sign_in_experience: 'Опыт входа в систему',
    connectors: 'Коннекторы',
    enterprise_sso: 'Единый вход в систему предприятия',
    security: 'Безопасность',
    webhooks: 'Webhooks',
    organizations: 'Организации',
    users: 'Управление пользователями',
    audit_logs: 'Журналы аудита',
    roles: 'Роли',
    docs: 'Документация',
    tenant_settings: 'Настройки',
    mfa: 'Multi-factor auth',
    customize_jwt: 'Пользовательский JWT',
    signing_keys: 'Ключи подписи',
    organization_template: 'Шаблон организации',
};
export default Object.freeze(tabs);
